<template lang="html">
  <div class="contact">
    <navbar></navbar>
    <div class="empty"></div>
    <box :border="border">
      <h2 slot="title" >{{ $t('menu.contact') }}</h2>
      <div slot="body">
        <div class="empty"></div>
        <contact-form :border="border"></contact-form>
        <div class="empty"></div>
        <contact-description :border="border"></contact-description>
        <div class="empty"></div>
        <Footer />
      </div>
    </box>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar';
import Box from '@/components/Box';
import ContactDescription from '@/components/ContactDescription';
import ContactForm from '@/components/ContactForm';
import Footer from  '@/components/Footer';
export default {
  name:'Contact',
  components: {
    Navbar,
    Box,
    ContactDescription,
    ContactForm,
    Footer
  },
  data(){
    return {
      category:this.$route.params.category,
      border:'border-plast',
    };
  },
  created(){
      if(this.category == 'tajhiz'){
          this.border = 'border-tajhiz';
      }
  }
}
</script>

<style lang="css">
.empty{
  padding: 30px;
}
</style>
